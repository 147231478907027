import { createContext, useContext, useState } from "react";

const UserDetailContext = createContext();

export const UserDetailProvider = (props) => {
  const { value, children } = props;
  const [userDetail , setUserDetail] = useState(null);
  return (
    <UserDetailContext.Provider
      value={{
        userDetail,
        setUserDetail
      }}
    >
      {children}
    </UserDetailContext.Provider>
  );
};

export const useUserDetailContext = () => useContext(UserDetailContext);
